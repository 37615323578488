import axios, { AxiosError, AxiosResponseHeaders } from 'axios';



const instance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },

  baseURL: "https://backoffice.genius99.org/",
  // baseURL: "https://e481-2a02-4780-28-55fb-00-1.ngrok-free.app/",
  // baseURL: "http://localhost:8888/",
  // https://e481-2a02-4780-28-55fb-00-1.ngrok-free.app/
  timeout: 15 * 1000,
  timeoutErrorMessage: 'oops, request timeout',
});

instance.interceptors.request.use(async request => {
  try {
    let accessToken;
    accessToken = localStorage.getItem('accessToken')

    // console.log("accessToken", accessToken);
    if (accessToken && accessToken !== null) {
      accessToken = 'Bearer ' + accessToken;
      request.headers.authorization = accessToken;
    }
    // request.fetchOptions = {
    //   mode: 'cors',
    //   cache: 'no-cache',
    //   credentials: 'same-origin',
    //   headers: {
    //     'Content-Type': 'application / json',
    //     'Authorization': accessToken
    //   },
    //   redirect: 'follow',
    //   referrerPolicy: 'unsafe-url',
    // };
  } catch (error) {
    console.warn("QUERY ERROR TRY CATCH", error.message);
  }
  return request;
});

// Add a response interceptor
instance.interceptors.response.use(
  response => {
    return response.data;
  },
  (err) => {
    console.warn('AXIOS ERROR', err.message);

    if (err.response) return Promise.reject(err.response.data);
    if (err.request) return Promise.reject(err.request);
    return Promise.reject(err.message);
  },
);

export default instance;
