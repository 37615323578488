
import { useAtom } from 'jotai';
import React from 'react';
import { chapterListAtom } from '../../../utils/Atoms';
import { useMutation } from '@tanstack/react-query';
import { ADD_CHAPTER_POST } from '../../../utils/Requests';



const AddChapterModal = ({ notify, close }) => {
  const [chapterList, setChapterList] = useAtom(chapterListAtom);
  const [chapterData, setChapterData] = React.useState({
    name: '',
    description: '',
  })
  function handleButtonClick() {
    console.log("Button Clicked");
    mutate(chapterData)
  }
  const { mutate } = useMutation({
    mutationKey: ['addChapter'],
    mutationFn: (data) => ADD_CHAPTER_POST(data),
    onSuccess: (data) => {
      console.log(data);
      if (data) {
        notify()
        setChapterList([data, ...chapterList])
      }
      close();
    },
    onError: (error) => {
      close();
    }
  })

  return (
    <div>
      <div className="popup-title">
        <h4>Add New Chapter</h4>
      </div>
      <input
        type="text"
        id="addchaptername"
        placeholder="Chapter Name"
        onChange={(e) => setChapterData({ ...chapterData, name: e.target.value })}
      />
      <input
        type="text"
        id="addchapterdescription"
        placeholder="Chapter Description"
        onChange={(e) => setChapterData({ ...chapterData, description: e.target.value })}
      />
      <button className="save" onClick={handleButtonClick}>
        Save
      </button>
    </div>
  );
};

export default AddChapterModal;

