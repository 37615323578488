import Editor from "react-simple-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useState, useEffect } from "react";
import Popup from "../../components/Popup";
import toast from "react-hot-toast";
import { Icon } from "../../Icons";
import AddDeckModal from "./Modals/AddDeck";
import AddSectionModal from "./Modals/AddSection";
import AddChapterModal from "./Modals/AddChapter";
import { useAtom } from "jotai";
import { categoryListAtom, chapterListAtom, deckListAtom, sectionListAtom } from "../../utils/Atoms";
import { useMutation } from "@tanstack/react-query";
import { CATEGORIES_GET, CHAPTERS_GET, CREATE_QUESTIONS_POST, DECKS_GET, SECTIONS_GET } from "../../utils/Requests";
import Select from 'react-select'
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import AddCategoryModal from "./Modals/AddCategory";
import AddOptionModal from "./Modals/AddOption";

const modules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' },],
    ['link', { 'color': ['red', 'blue', 'green'] }],
    ['clean']
  ],
}

function AddQuestion() {
  const [chapterList, setChapterList] = useAtom(chapterListAtom);
  const [sectionList, setSectionList] = useAtom(sectionListAtom);
  const [deckList, setDeckList] = useAtom(deckListAtom);
  const [categoryList, setCategoryList] = useAtom(categoryListAtom);
  const [isDeckOpen, setIsDeckOpen] = useState(false);
  const [isChapterOpen, setIsChapterOpen] = useState(false);
  const [isSectionOpen, setIsSectionOpen] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const notify = () => toast.success("İçerik başarıyla eklendi!");
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isOpen]);

  const closePopup = () => {
    setIsOpen(false);
    setIsChapterOpen(false);
    setIsSectionOpen(false);
    setIsDeckOpen(false);
    setIsCategoryOpen(false);
    setIsOptionOpen(false);
  };



  const { mutate: chaptersGetMutate } = useMutation({ mutationKey: ["chapters get"], mutationFn: CHAPTERS_GET, onSuccess: (data) => { if (data) setChapterList(data) } })
  const { mutate: sectionsGetMutate } = useMutation({ mutationKey: ["sections get"], mutationFn: SECTIONS_GET, onSuccess: (data) => { if (data) setSectionList(data) } })
  const { mutate: decksGetMutate } = useMutation({
    mutationKey: ["decks get"], mutationFn: DECKS_GET, onSuccess: (data) => {
      console.log('data===>', data);

      if (data) setDeckList(data)
    }
  })
  const { mutate: categoriesGetMutate } = useMutation({ mutationKey: ["categories get"], mutationFn: CATEGORIES_GET, onSuccess: (data) => { if (data) setCategoryList(data) } })
  useEffect(() => { chaptersGetMutate(); sectionsGetMutate(); decksGetMutate(); categoriesGetMutate(); }, [])
  console.log('AAAAAAAAA=>', deckList);

  const [addingQuestionData, setAddingQuestionData] = useState({
    chapter_id: "",
    section_id: "",
    deck_id: "",
    question: "",
    description: "",
    type: "", // 'spot', 'case','board'
    options: [],
    tags: [],
    correct_option_index: null
  })
  function onChangeQuestion(e) { setAddingQuestionData(prev => ({ ...prev, question: e })) }
  function onChangeDescription(e) { setAddingQuestionData(prev => ({ ...prev, description: e })) }

  function handleAddQuestion(answer) {
    console.log("params ==>", answer);
    let updatingData = addingQuestionData

    if (addingQuestionData.options.length === 0) { updatingData.options = [answer] }
    else { updatingData.options = [...updatingData.options, answer] }
    console.log("updatingData ==>", updatingData);
    // if (answer.is_true_option) {
    // let count = 0
    // updatingData.options.forEach(element => {
    //   if (element.is_true_option === true) { updatingData.correct_option_index = count }
    //   count += 1
    // });
    const index = updatingData.options.findIndex(item => item.is_true_option)
    console.log("index ==>", index);
    if (index === -1) updatingData.correct_option_index = null
    else { updatingData.correct_option_index = index }
    // }
    setAddingQuestionData(updatingData)
  }

  console.log("addingQuestionData ==>", addingQuestionData);
  useEffect(() => {
    console.log("addingQuestionData ==>", addingQuestionData);
  }, [addingQuestionData])

  const { mutate: addQuestion } = useMutation({
    mutationKey: ["addQuestion"],
    mutationFn: () => {
      console.log("data ==>", addingQuestionData);
      return CREATE_QUESTIONS_POST(addingQuestionData)
    },
    onSuccess: (data) => {
      console.log('Success', data);
      notify()
      setAddingQuestionData(prev => ({ ...prev, options: [], tags: [], correct_option_index: null }))
    },
    onError: (error) => {
      console.log('Error', error);
    }
  })
  function handleAddQuestiontoDatabase() {
    if (
      addingQuestionData.question === "" ||
      addingQuestionData.description === "" ||
      addingQuestionData.type === "" ||
      addingQuestionData.options.length === 0 ||
      addingQuestionData.correct_option_index === null
    ) {
      alert("Please fill all the fields")
      return
    }
    addQuestion()
  }

  return (
    <div className="container">
      <Popup isOpen={isDeckOpen} onClose={closePopup} content={<AddDeckModal notify={notify} close={closePopup} />} />
      <Popup isOpen={isSectionOpen} onClose={closePopup} content={<AddSectionModal notify={notify} close={closePopup} />} />
      <Popup isOpen={isChapterOpen} onClose={closePopup} content={<AddChapterModal notify={notify} close={closePopup} />} />
      <Popup isOpen={isCategoryOpen} onClose={closePopup} content={<AddCategoryModal notify={notify} close={closePopup} />} />
      <Popup isOpen={isOptionOpen} onClose={closePopup} content={<AddOptionModal notify={notify} close={closePopup} handleAddQuestion={handleAddQuestion} />} />

      <div className="row">
        {/* //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
        <div className="left">
          <div className="left-wrapper">
            <div className="left-item flex new-item">
              <button id="newchapters" onClick={() => setIsChapterOpen(true)} >CHAPTERS<i><Icon name={"btnplus"} size={20} /></i></button>
            </div>
            {/* //! Chapter Selection */}
            <div className="left-item">
              <Select id="chapters" isSearchable={true} name="chapters"
                value={chapterList ? chapterList.find(item => item._id === addingQuestionData?.chapter_id) : []}
                options={chapterList}
                getOptionLabel={(option) => `${option.name}: ${option._id}`}
                getOptionValue={(option) => option._id}
                onChange={(e) => setAddingQuestionData({ ...addingQuestionData, chapter_id: e._id })}
              />
            </div>
            {/* //! Chapter Selection */}
            <div className="left-item flex new-item">
              <button id="newsections" onClick={() => setIsSectionOpen(true)}  >SECTIONS<i><Icon name={"btnplus"} size={20} /></i></button>
            </div>
            <div className="left-item">
              <Select id="sections" isSearchable={true} name="sections"
                value={sectionList ? sectionList.find(item => item._id === addingQuestionData?.section_id) : []}
                options={addingQuestionData.chapter_id ? sectionList.filter(item => item.chapter_id === addingQuestionData.chapter_id) : []}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => option._id}
                onChange={(e) => setAddingQuestionData({ ...addingQuestionData, section_id: e._id })}
              />
            </div>
            {/* //todo::::: */}
            <div className="left-item flex new-item">
              <button id="newdecks" onClick={() => setIsDeckOpen(true)} >DECKS<i><Icon name={"btnplus"} size={20} /></i></button>
            </div>
            {/* //todo::::: */}
            <div className="left-item">
              <Select id="decks" isSearchable={true} name="decks"
                value={deckList ? deckList.find(item => item._id === addingQuestionData?.deck_id) : []}
                options={addingQuestionData.section_id ? deckList.filter(item => item.section_id === addingQuestionData.section_id) : []}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => option._id}
                onChange={(e) => setAddingQuestionData({ ...addingQuestionData, deck_id: e._id })}
              />
            </div>

            {/* //!::::: ADD CATEGORY */}
            <div className="left-item flex new-item">
              <button id="newdecks" onClick={() => setIsCategoryOpen(true)} >CATEGORIES<i><Icon name={"btnplus"} size={20} /></i></button>
            </div>
            {/* //!::::: ADD CATEGORY*/}
            <div className="  right-flex  ">
              <Select id="cat" isSearchable={true} name="decks" className="item-width"
                // value={deckList.find(item => item._id === addingQuestionData?.deck_id)}
                placeholder="Select Chapter Category"
                options={categoryList.filter(item => item.type === "chapter")}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => option._id}
                onChange={(e) => setAddingQuestionData({ ...addingQuestionData, tags: [e._id] })}
              />
              <Select id="seccat" isSearchable={true} name="decks" className="item-width"
                // value={deckList.find(item => item._id === addingQuestionData?.deck_id)}
                placeholder="Select Section Category"
                options={addingQuestionData.tags?.[0] &&
                  categoryList.filter(item => (item.type === "section" && item.parent_id === addingQuestionData.tags?.[0]))}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => option._id}
              // onChange={(e) => setAddingQuestionData({ ...addingQuestionData, deck_id: e._id })}
              />
            </div>

          </div>
        </div>
        {/* //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
        <div className="right">
          <div className="right-wrapper">
            <div className="right-item">
              <label>Question</label>
              <ReactQuill theme="snow" modules={modules} value={addingQuestionData.question}
                onChange={onChangeQuestion} placeholder="Add Question"
                style={{ width: "100%", backgroundColor: "rgba(255,255,255,0.5)" }}
              />
            </div>
            <div className="right-item">
              <label>Explaination</label>
              <ReactQuill theme="snow" modules={modules} value={addingQuestionData.description}
                onChange={onChangeDescription} placeholder="Add Explaination"
                style={{ width: "100%", backgroundColor: "rgba(255,255,255,0.5)" }}
              />
            </div>
            <div className="right-item">
              <label>Select Type</label>
              <Select id="type" name="type" isSearchable={false}
                options={[{ name: 'spot' }, { name: 'case' }, { name: 'board' }]}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => option.name}
                onChange={(e) => setAddingQuestionData({ ...addingQuestionData, type: e.name })}
              />
            </div>

            <div className="right-item">
              <label>Options</label>
              {addingQuestionData.options.map((item, index) => {
                return (
                  <div key={index} className="answer mr-bot" >
                    <p style={{ fontSize: 30 }} >{item.is_true_option ? "✅" : "❌"}</p>
                    <div className="row center" >
                      <p>{item.answer}</p>
                    </div>
                    <div style={{ width: 50 }}
                      onClick={() => setAddingQuestionData({ ...addingQuestionData, options: addingQuestionData.options.filter((_, i) => i !== index) })}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="red" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                      </svg>
                    </div>
                  </div>
                )
              })}
            </div>

            <div className="right-button">
              <button id="savequestion" className="save" onClick={setIsOptionOpen}>
                Add Option
              </button>
            </div>
            <div className="right-button">
              <button id="savequestion" className="save" onClick={handleAddQuestiontoDatabase} style={{ backgroundColor: 'green' }} >
                Save Question
              </button>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default AddQuestion;







// <div className="left-item flex new-item">
//   <button
//     id="newcategory"
//     onClick={() =>
//       openPopup(
//         <div>
//           <div className="popup-title">
//             <h4>Add New Category</h4>
//           </div>
//           <input
//             type="text"
//             id="addcatname"
//             placeholder="Category Name"
//           />
//           <select id="typeforcat" name="typeforcat">
//             <option value="1">1</option>
//             <option value="2">2</option>
//             <option value="3">3</option>
//             <option value="4">4</option>
//             <option value="5">5</option>
//           </select>
//           <select id="parrentidforcat" name="parrentidforcat">
//             <option value="1">1</option>
//             <option value="2">2</option>
//             <option value="3">3</option>
//             <option value="4">4</option>
//             <option value="5">5</option>
//           </select>
//           <button className="save" onClick={handleButtonClick}>
//             Save
//           </button>
//         </div>
//       )
//     }
//   >
//     Category
//     <i>
//       <Icon name={"btnplus"} size={20} />
//     </i>
//   </button>
// </div>