import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";




export const accessTokenAtom = atomWithStorage('accessToken', '')
export const isSignedInAtom = atomWithStorage('isSignedIn', false)

export const chapterListAtom = atom([])
export const sectionListAtom = atom([])
export const deckListAtom = atom([])
export const categoryListAtom = atom([])