import React from "react";
import { Icon } from "../Icons";

const Popup = ({ isOpen, onClose, content }) => {
  if (!isOpen) return null;

  return (
    <div className="popup">
      <div className="popup-overlay"  >
        <div className="popup-content" onClick={(e) => e.stopPropagation()}>
          <button className="popup-close" onClick={onClose}>
            <Icon name="btnclose" size={24} />
          </button>
          {content}
        </div>
      </div>
    </div>
  );
};

export default Popup;
