import React from 'react';
import { chapterListAtom, deckListAtom, sectionListAtom } from '../../../utils/Atoms';
import { useAtom } from 'jotai';
import Select from 'react-select'
import { useMutation } from '@tanstack/react-query';
import { ADD_DECK_POST } from '../../../utils/Requests';


const AddDeckModal = ({ notify, close }) => {


  const [sectionList,] = useAtom(sectionListAtom);
  const [deckList, setDeckList] = useAtom(deckListAtom);
  const [deckData, setDeckData] = React.useState({
    name: "",
    description: "",
    section_id: "",
    chapter_id: "",
  })
  function handleButtonClick() {
    console.log("Button Clicked");
    if (deckData.name === "" || deckData.description === "" || deckData.chapter_id === "" || deckData.section_id === "") {
      alert("Please fill all the fields")
      return
    }
    mutate(deckData)
  }
  const { mutate } = useMutation({
    mutationKey: ['adddeck'],
    mutationFn: (data) => ADD_DECK_POST(data),
    onSuccess: (data) => {
      console.log(data);
      if (data) {
        notify()
        setDeckList([data, ...deckList])
      }
      close();
    },
    onError: (error) => {
      close();
    }
  })
  React.useEffect(() => {
    console.log(deckData);
  }, [deckData])

  function handleSelectSection(e) {
    setDeckData(prev => ({ ...prev, section_id: e._id }))
    sectionList.find((section) => {
      if (section._id === e._id) {
        setDeckData(prev => ({ ...prev, chapter_id: section.chapter_id }))
      }
    })
  }
  return (
    <div>
      <div className="popup-title">
        <h4>Add New Deck</h4>
      </div>
      <input
        type="text"
        id="adddeckname"
        placeholder="Deck Name"
        onChange={(e) => setDeckData({ ...deckData, name: e.target.value })}
      />
      <input
        type="text"
        id="adddeckdescription"
        placeholder="Deck Description"
        onChange={(e) => setDeckData({ ...deckData, description: e.target.value })}
      />
      <Select id="type" name="section" isSearchable={true} placeholder="Select relative Section"
        options={sectionList}
        getOptionLabel={(option) => `${option.name}`}
        getOptionValue={(option) => option._id}
        onChange={handleSelectSection}
      />
      <button className="save" onClick={handleButtonClick}>
        Save
      </button>
    </div>
  );
};

export default AddDeckModal;