import instance from "./Axios";



export const SELF_GET = () => instance.get('/self')

export const LOGIN_POST = (data) => instance.post('/auth/signin', data)


//! QUESTIONS
export const CREATE_QUESTIONS_POST = (question_data) => instance.post('/question/create', question_data)

//! GET CHAPTERS
export const CHAPTERS_GET = () => instance.get('/chapters')
export const ADD_CHAPTER_POST = (data) => instance.post('/chapters/create', data)

//! GET SECTIONS
export const SECTIONS_GET = () => instance.get('/sections')
export const ADD_SECTION_POST = (data) => instance.post('/sections/create', data)

//! GET DECKS
export const DECKS_GET = () => instance.get('/decks')
export const ADD_DECK_POST = (data) => instance.post('/decks/create', data)

//! GET CATEGORIES
export const CATEGORIES_GET = () => instance.get('/categories')
export const ADD_CATEGORY_POST = (data) => instance.post('/categories/create', data)