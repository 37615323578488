const ButtonPlus = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2L12 22"
        stroke="#fefefe"
        stroke-width="3"
        stroke-linecap="round"
      />
      <path
        d="M22 12L2 12"
        stroke="#fefefe"
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  );
};
const ButtonClose = ({ size }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 1L1 25"
        stroke="#33363F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M0.999999 1L25 25"
        stroke="#33363F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const Icon = ({ name, size = 24 }) => {
  const icons = {
    btnplus: ButtonPlus,
    btnclose: ButtonClose,
  };

  const Component = icons[name];
  return <Component size={size} />;
};
export { Icon };
