

import { useAtom } from "jotai";
import React, { useState, useEffect } from "react";
import Select from 'react-select'
import { categoryListAtom, chapterListAtom } from "../../../utils/Atoms";
import { ADD_CATEGORY_POST } from "../../../utils/Requests";
import { useMutation } from "@tanstack/react-query";

const typeData = [
  { name: "chapter", },
  { name: "section" },
  // { name: "subSection" }
]

const AddCategoryModal = ({ notify, close }) => {
  const [chapterList, setChapterList] = useAtom(chapterListAtom);
  const [categoryList, setCategoryList] = useAtom(categoryListAtom);
  const [sectionCategoriesWithParent, setSectionCategoriesWithParent] = useState([])
  // const chapterCategories = categoryList.filter((category) => category.type === "chapter")
  // const sectionCategories = categoryList.filter((category) => category.type === "section")



  React.useEffect(() => {
    const chapterCategories = categoryList.filter((category) => category.type === "chapter")
    const sectionCategories = categoryList.filter((category) => category.type === "section")
    // console.log('===>>>>', sectionCategories);

    const a = chapterCategories.map((category) => {
      const parent = chapterList.find((chapter) => chapter._id === category.chapter_id)
      return { ...category, category_name: parent?.name }
    })
    console.log(a);
    setSectionCategoriesWithParent(a)
  }, [chapterList, categoryList,])

  const [categoryData, setCategoryData] = useState({
    name: '',
    type: '',
    parent_id: '',
    chapter_id: ''
  })




  function handleButtonClick() {
    console.log("Button Clicked");
    mutate(categoryData)

  }

  const { mutate } = useMutation({
    mutationKey: ['addCategory'],
    mutationFn: (data) => ADD_CATEGORY_POST(data),
    onSuccess: (data) => {
      console.log(data);
      if (data) {
        notify()
        setCategoryList([data, ...categoryList])
      }
      close();
    },
    onError: (error) => {
      close();
    }
  })

  React.useEffect(() => {
    console.log(categoryData);
  }, [categoryData])
  return (
    <>
      <div>
        <div className="popup-title">
          <h4>Add New Category</h4>
        </div>
        <Select id="type" name="type"
          isSearchable={true} placeholder="Select Category Type"
          options={typeData}
          getOptionLabel={(option) => `${option.name}`}
          getOptionValue={(option) => option.name}
          onChange={(e) => setCategoryData(prev => { return { ...prev, type: e.name } })}

        />
        <div className="popup-title" />
        {/*//!! SELECT RELATIVE CHAPTER FOR CHAPTER CATEGORY */}
        {categoryData.type === "chapter" ?
          <Select id="type" name="type" isSearchable={true} placeholder="Select relative Chapter"
            options={chapterList}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => option._id}
            onChange={(e) => setCategoryData(prev => { return { ...prev, chapter_id: e._id } })}
          /> : <></>}

        <div className="popup-title" />
        {/*//!! SELECT RELATIVE CHAPTER CATEGORY FOR SECTION CATEGORY */}
        {categoryData.type === "section" &&
          <Select id="type" name="type" isSearchable={true} placeholder="Select Parent Chapter Category"
            options={sectionCategoriesWithParent}
            // options={chapterList}
            getOptionLabel={(option) => `${option.category_name}: ${option.name}`}
            getOptionValue={(option) => option._id}
            onChange={(e) => setCategoryData(prev => { return { ...prev, parent_id: e._id } })}
          />}

        <div className="popup-title" />
        {/* {categoryData.type === "subSection" &&
          <Select id="type" name="type" isSearchable={true} placeholder="Select Parent Section Category"
            options={sectionCategories.filter((category) => category.parent_id === categoryData.chapter_id)}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => option._id}
            onChange={(e) => setCategoryData(prev => { return { ...prev, parent_id: e._id } })}
          />
        } */}
        <div className="popup-title" />
        <input
          type="text"
          id="addcatname"
          placeholder="Category Name"
          onChange={(e) => setCategoryData(prev => { return { ...prev, name: e.target.value } })}
        />

        <button className="save" onClick={handleButtonClick}>
          Save
        </button>
      </div>
    </>
  )
}

export default AddCategoryModal;

