import React from 'react';
import { chapterListAtom, deckListAtom, sectionListAtom } from '../../../utils/Atoms';
import { useAtom } from 'jotai';
import Select from 'react-select'
import { useMutation } from '@tanstack/react-query';
import { ADD_DECK_POST } from '../../../utils/Requests';
import ReactSwitch from 'react-switch';


const AddOptionModal = ({ notify, close, handleAddQuestion }) => {
  const [optionData, setOptionData] = React.useState({
    answer: "",
    description: "",
    is_true_option: false,
    rank: "",
  })

  function handleButtonClick() {
    console.log("Button Clicked");
    if (optionData.answer === "" || optionData.description === "") {
      alert("Please fill all the fields")
      return
    }
    handleAddQuestion(optionData)
    close();
    notify()
  }

  function handleToggle() {
    setOptionData(prev => ({ ...prev, is_true_option: !prev.is_true_option }))
  }
  React.useEffect(() => {
    console.log(optionData);
  }, [optionData])
  return (
    <div>
      <div className="popup-title">
        <h4>Add New Deck</h4>
      </div>
      <input
        type="text"
        id="adddeckname"
        placeholder="Answer"
        onChange={(e) => setOptionData(prev => ({ ...prev, answer: e.target.value }))}
      />
      <input
        type="text"
        id="adddeckdescription"
        placeholder="Answer Description"
        onChange={(e) => setOptionData(prev => ({ ...prev, description: e.target.value }))}
      />
      <div className='row center '>
        <p>Is True Option???</p>
        <ReactSwitch onChange={handleToggle} checked={optionData.is_true_option} />
      </div>
      {/* <Select id="type" name="section" isSearchable={true} placeholder="Select relative Section"
        options={[]}
        getOptionLabel={(option) => `${option.name}`}
        getOptionValue={(option) => option._id}
      // onChange={handleSelectSection}
      /> */}

      <button className="save" onClick={handleButtonClick}>
        Save
      </button>
    </div>
  );
};

export default AddOptionModal;