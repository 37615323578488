import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { LOGIN_POST, SELF_GET } from '../../utils/Requests';
import { useAtom } from 'jotai';
import { isSignedInAtom } from '../../utils/Atoms';

const Login = () => {
  const [isSignedIn, setIsSignedIn] = useAtom(isSignedInAtom);
  const [loginData, setLoginData] = useState({ email: '', password: '' });

  const handleEmailChange = (e) => {
    setLoginData(prev => { return { ...prev, email: e.target.value } });
  };
  const handlePasswordChange = (e) => {
    setLoginData(prev => { return { ...prev, password: e.target.value } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your login logic here
    console.log('Login Data===:', loginData);
    mutate()
  };

  const { mutate } = useMutation({
    mutationKey: ['login'],
    mutationFn: () => LOGIN_POST(loginData),
    onSuccess: (data) => {
      console.log('LOGIN Data:', data);
      setIsSignedIn(true);
      localStorage.setItem('accessToken', data.accessToken);
    },
    onError: (error) => {
      console.log('Error:', error);
    },
  })
  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
          <input type="email" value={loginData.email} onChange={handleEmailChange} />
        </div>
        <div>
          <label>Password:</label>
          <input type="password" value={loginData.password} onChange={handlePasswordChange} />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;