
import React from 'react';
import { chapterListAtom, sectionListAtom } from '../../../utils/Atoms';
import { useAtom } from 'jotai';
import { ADD_SECTION_POST } from '../../../utils/Requests';
import { useMutation } from '@tanstack/react-query';
import Select from 'react-select'


const AddSectionModal = ({ notify, close }) => {

  const [chapterList, setChapterList] = useAtom(chapterListAtom);
  const [sectionList, setSectionList] = useAtom(sectionListAtom);
  const [sectiondata, setSectionData] = React.useState({
    name: "",
    description: "",
    chapter_id: ""
  })
  function handleButtonClick() {
    console.log("Button Clicked");
    if (sectiondata.name === "" || sectiondata.description === "" || sectiondata.chapter_id === "") {
      alert("Please fill all the fields")
      return
    }
    mutate(sectiondata)
  }
  const { mutate } = useMutation({
    mutationKey: ['addChapter'],
    mutationFn: (data) => ADD_SECTION_POST(data),
    onSuccess: (data) => {
      console.log(data);
      if (data) {
        notify()
        setSectionList([data, ...sectionList])
      }
      close();
    },
    onError: (error) => {
      close();
    }
  })
  React.useEffect(() => {
    console.log(sectiondata);
  }, [sectiondata])

  return (

    <div>
      <div className="popup-title">
        <h4>Add New Section</h4>
      </div>
      <input
        type="text"
        id="addsectionname"
        placeholder="Section Name"
        onChange={(e) => setSectionData({ ...sectiondata, name: e.target.value })}
      />
      <input
        type="text"
        id="addsectiondescription"
        placeholder="Section Description"
        onChange={(e) => setSectionData({ ...sectiondata, description: e.target.value })}
      />
      <Select id="type" name="type" isSearchable={true} placeholder="Select relative Chapter"
        options={chapterList}
        getOptionLabel={(option) => `${option.name}`}
        getOptionValue={(option) => option._id}
        onChange={(e) => setSectionData({ ...sectiondata, chapter_id: e._id })}

      />
      <button className="save" onClick={handleButtonClick}>
        Save
      </button>
    </div>
  );
};

export default AddSectionModal;

