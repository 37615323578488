import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Router from "./Router";
import { Helmet } from "react-helmet";
const queryClient = new QueryClient({})

function App() {



  return (
    <QueryClientProvider client={queryClient}>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <title>My Title</title> */}
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        {/* <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" /> */}
        {/* <meta http-equiv="Content-Security-Policy" content="default-src 'self'"></meta> */}
      </Helmet>
      <Router />
    </QueryClientProvider>
  );
}

export default App;
